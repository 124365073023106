var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ppi_pageZone_container",attrs:{"id":"psi_pagecontainer_master"}},[_vm._m(0),_c('ValidationObserver',{ref:"formstepPiH",staticClass:"ppi_contentZone spacerRowTop20",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
var passed = ref.passed;
return [_c('q-form',{ref:"myFormPiH",staticClass:"formDetailZone ",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.doSubmitData)},"reset":_vm.onStepFormPihReset}},[_c('ValidationProvider',{ref:"fldx_tieneAuto",staticClass:"canna_forma_campo_container",staticStyle:{"margin-left":"-10px"},attrs:{"name":"tiene_auto","immediate":true,"tag":"div","rules":{ required: true, oneOf: _vm.z_allowTieneAuto }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.frmData.tienes_auto),expression:"frmData.tienes_auto"}],attrs:{"type":"hidden","name":"tiene_licencia"},domProps:{"value":(_vm.frmData.tienes_auto)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.frmData, "tienes_auto", $event.target.value)}}}),_c('q-option-group',{staticClass:"canna_optionGroup_A fldx_campoTieneLicenciaYesNO",attrs:{"inline":"","name":"tieneautoOpt","type":"radio","keep-color":"","options":[
              { label: 'Si', value: 97, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
              { label: 'No', value: 98 , color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }
            ],"color":(invalid && validated) ? 'positive' : 'canna_ColorRadio'},model:{value:(_vm.frmData.tienes_auto),callback:function ($$v) {_vm.$set(_vm.frmData, "tienes_auto", $$v)},expression:"frmData.tienes_auto"}}),((invalid && validated && errors[0] !==undefined))?[_c('div',{staticClass:"q-field--error q-field__bottom",staticStyle:{"flex":"1 1 0%","justify-content":"flex-start","padding":"0px 0px 0px 10px","display":"flex","align-items":"center"}},[_vm._v("\n             "+_vm._s(errors[0])+"\n            ")])]:_vm._e()]}}],null,true)}),((_vm.frmData.tienes_auto===97))?_c('div',{staticClass:"dataSection_container spacerRowTop48"},[_c('div',{staticClass:"dataSection_title"},[_vm._v("¿Tienes tarjeta de circulación vigente?")]),_c('div',{staticClass:"datasectionItem dataSection_separadorx"},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{ref:"fldx_tieneTarjeta",staticClass:"col-12 canna_forma_campo_container",staticStyle:{"margin-left":"-10px"},attrs:{"name":"tiene_tarjeta","immediate":true,"tag":"div","rules":{ 
                  required: (_vm.frmData.tienes_auto===97)? true : false , 
                  oneOf: _vm.z_allowTieneTarjeta
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var invalid = ref.invalid;
                var validated = ref.validated;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.frmData.tienes_tarjeta),expression:"frmData.tienes_tarjeta"}],attrs:{"type":"hidden","name":"tiene_tarjeta"},domProps:{"value":(_vm.frmData.tienes_tarjeta)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.frmData, "tienes_tarjeta", $event.target.value)}}}),_c('q-option-group',{staticClass:"canna_optionGroup_A fldx_campoTieneLicenciaYesNO",attrs:{"inline":"","name":"tieneTCOpt","type":"radio","keep-color":"","options":[
                      { label: 'Si', value: 731, color:(invalid && validated && errors[0] !==undefined || _vm.frmData.tienes_tarjeta==null) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                      { label: 'No', value: 732 , color:(invalid && validated && errors[0] !==undefined || _vm.frmData.tienes_tarjeta==null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                    ]},model:{value:(_vm.frmData.tienes_tarjeta),callback:function ($$v) {_vm.$set(_vm.frmData, "tienes_tarjeta", $$v)},expression:"frmData.tienes_tarjeta"}}),((invalid && validated && errors[0] !==undefined))?[_c('div',{staticClass:"q-field--error q-field__bottom",staticStyle:{"flex":"1 1 0%","justify-content":"flex-start","padding":"0px 0px 0px 10px","display":"flex","align-items":"center"}},[_vm._v("\n                    "+_vm._s(errors[0].replace('El campo','').replace('tiene_tarjeta','¿Tienes tarjeta de circulación vigente?'))+"\n                    ")])]:_vm._e()]}}],null,true)})],1)])]):_vm._e(),_c('div',{staticClass:"ppi_pz_footer"},[_c('div',{staticClass:"rowButtons"},[_c('div',[_c('q-btn',{attrs:{"type":"button","outline":"","unelevated":"","color":"app_mainBtn","icon":"r_chevron_left","label":"Atrás","no-caps":""},on:{"click":function($event){$event.stopPropagation();return _vm.doPrevScreen.apply(null, arguments)}}})],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('q-btn',{attrs:{"type":"submit","unelevated":"","color":"app_mainBtn","icon-right":"r_chevron_right","label":"Siguiente","no-caps":"","disable":((passed || valid) )? false : true}}),(_vm.qa_allowReviewLink===true)?[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"link_actionTextAlt action_item spacerRowTop16",on:{"click":function($event){$event.stopPropagation();return _vm.goTo_PreviewPage.apply(null, arguments)}}},[_vm._v("\n                  Ir a mi resumen\n                ")])])]:_vm._e()],2)])])],1)]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ppi_pz_header noselect"},[_c('div',{staticClass:"pageZone_title"},[_vm._v("\n      ¿Cuentas con automóvil o motocicleta propios?\n    ")])])}]

export { render, staticRenderFns }