<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">

    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        ¿Cuentas con automóvil o motocicleta propios?
      </div>
    </div>

    <ValidationObserver ref="formstepPiH" tag="div"  class="ppi_contentZone spacerRowTop20" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPiH"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPihReset"
        class="formDetailZone "
      >

        <ValidationProvider ref="fldx_tieneAuto" name="tiene_auto" :immediate="true" class="canna_forma_campo_container" tag="div" style="margin-left: -10px;"
          :rules=" { required: true, oneOf: z_allowTieneAuto } "
          v-slot="{ errors, invalid, validated  }"
        >
            <input type="hidden" name="tiene_licencia"  v-model="frmData.tienes_auto">
            <q-option-group
              class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
              inline
              name="tieneautoOpt"
              type="radio"
              keep-color
              :options="[
                { label: 'Si', value: 97, color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                { label: 'No', value: 98 , color:(invalid && validated && errors[0] !==undefined) ? 'cannaRadioError' : 'canna_ColorRadio' }
              ]"
              :color="(invalid && validated) ? 'positive' : 'canna_ColorRadio'"
              v-model="frmData.tienes_auto"
            />
            <template v-if="(invalid && validated && errors[0] !==undefined)">
              <div class="q-field--error q-field__bottom" 
                style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
              >
               {{ errors[0] }}
              </div>
            </template>
        </ValidationProvider>


        <div class="dataSection_container spacerRowTop48" v-if="(frmData.tienes_auto===97)">
          <div class="dataSection_title">¿Tienes tarjeta de circulación vigente?</div>
          <div class="datasectionItem dataSection_separadorx">
            <div class="row">
                <ValidationProvider 
                  ref="fldx_tieneTarjeta" name="tiene_tarjeta" :immediate="true" 
                  class="col-12 canna_forma_campo_container" tag="div" style="margin-left: -10px;"
                  :rules=" { 
                    required: (frmData.tienes_auto===97)? true : false , 
                    oneOf: z_allowTieneTarjeta
                  } "
                  v-slot="{ errors, invalid, validated  }"
                >
                    <input type="hidden" name="tiene_tarjeta"  v-model="frmData.tienes_tarjeta">
                    <q-option-group
                      class="canna_optionGroup_A fldx_campoTieneLicenciaYesNO"
                      inline
                      name="tieneTCOpt"
                      type="radio"
                      keep-color
                      :options="[
                        { label: 'Si', value: 731, color:(invalid && validated && errors[0] !==undefined || frmData.tienes_tarjeta==null) ? 'cannaRadioError' : 'canna_ColorRadio' }, 
                        { label: 'No', value: 732 , color:(invalid && validated && errors[0] !==undefined || frmData.tienes_tarjeta==null) ? 'cannaRadioError' : 'canna_ColorRadio' }
                      ]"
                      
                      v-model="frmData.tienes_tarjeta"
                    /><!-- :color="((invalid && validated) || frmData.tienes_tarjeta==null) ? 'positive' : 'canna_ColorRadio'" -->
                    <template v-if="(invalid && validated && errors[0] !==undefined)">
                      <div class="q-field--error q-field__bottom" 
                        style="flex: 1 1 0%; justify-content: flex-start; padding: 0px 0px 0px 10px; display: flex; align-items: center;"
                      >
                      {{ errors[0].replace('El campo','').replace('tiene_tarjeta','¿Tienes tarjeta de circulación vigente?') }}
                      </div>
                    </template>
                </ValidationProvider>
            </div>
          </div>
        </div>



        <div class="ppi_pz_footer">
          <div class="rowButtons">

            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Siguiente" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
const Differify = require('@netilon/differify')
const differify = new Differify({ mode: { object: 'DIFF', array: 'DIFF' } })

import { requestPack } from '../../../../boot/axios' // APP code 
import logOut_Mixin from '../../../../mixins/requestMix.js'
import myplatform_Mixin from '../../../../mixins/platformMix.js'
import helperBase_Mixin from '../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'

const tplpih_Data = {
  tienes_auto: 97,
  tienes_tarjeta: null 
}

export default {
  name: 'psi_screen_tieneauto',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{

  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      //-----------
      myDifferify: differify,
      //-----------
      zloadingData: false,
      processingData: false,
      //-----------
      z_allowTieneAuto: [97,98],
      z_allowTieneTarjeta: [731,732],
      profileRecords:[], //Los registros previamente guardados en este perfil 
      frmDataOriginal: JSON.parse(JSON.stringify(tplpih_Data)),
      frmData: JSON.parse(JSON.stringify(tplpih_Data))
    }
  },
  computed: {
    cat_tieneAuto () { // catalogo id = 13 Tiene auto (si:97 no:98)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(13) : this.getHelperCat(54)
    },
    cat_tieneTarjetaCirc () { // catalogo id = 54 tarjeta circulacion (si:731 no:732)
      return (this.is_Mobile === true || this.$q.screen.lt.md) ?  this.getHelperCatMobil(54) : this.getHelperCat(54)
    },
    mainErrors () {
      var countx = 0
      if (this.initFormFlag) {
        try {
          if (this.$refs.formstepPiH) {
            if (this.$refs.formstepPiH.errors) {
              var OBJKeys = Object.keys(this.$refs.formstepPiH.errors)
              OBJKeys.forEach(itemX => {
                countx += this.$refs.formstepPiH.errors[itemX].length
              })
            }
          }
        } catch (e) {
          console.log(e)
          countx += (-1)
        }
      }
      return countx
    }
  },
  watch: {
    'frmData.tienes_auto': function (newVal, oldVal) { 
      if (newVal!==oldVal){ 
        if (newVal===98){
          this.frmData.tienes_tarjeta = null 
          this.$forceUpdate()
          if (this.$refs.fldx_tieneTarjeta){
            this.$refs.fldx_tieneTarjeta.reset()
          }
        }
      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_tieneauto -- test')
    },
    showLoadx ( value, type ){
      // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      var newRuta = '/dashboard/personal/direccion-c'
      this.$cannaDebug('-- psiPage_tieneauto -- do Prev Screen --- ' + newRuta)
      this.$router.push(newRuta)
    },
    onStepFormPihReset () {

    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_tieneauto -- do Submit Data --- ' + newRuta)
      var test = 11 
      var tmpArrTest
      var toAdd = [], toRemove = [], toPatch = []
      var allowNextPage = false 
      if (this.mainErrors > 0) {
        return false
      }
      this.showLoadx(true, 1) 
      var originalRecords = JSON.parse(JSON.stringify(this.profileRecords))
      var originalValues = JSON.parse(JSON.stringify(this.frmDataOriginal))
      var newValues =  JSON.parse(JSON.stringify(this.frmData)) 

      var testA = this.myDifferify.compare(originalValues, newValues)
      if (testA.changes > 0 ) { // Que existan valores...! 
        test = 100 

        // Tiene auto (si:97 no:98)
        // tarjeta circulacion (si:731 no:732)

        if (testA._.tienes_auto.status === 'MODIFIED' && testA._.tienes_auto.current === 98 ) {
          // Si es no automatico se borran los otros valores  
          toAdd.push(testA._.tienes_auto.current)
          //Revisar si estan los registros de SI y de circulacion 
          tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === 97)
          if (tmpArrTest.length > 0) { // Solo si existe el registro ..! para aplicar en remove
            toRemove.push(97)
          }
          tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === 731 || item.catalogo_id === 732)
          if (tmpArrTest.length > 0) { // Solo si existe el registro ..! para aplicar en remove
            toRemove.push(this.frmDataOriginal.tienes_tarjeta) // Debe estar el valor 
          }
        } else if (
          (testA._.tienes_auto.status === 'MODIFIED' || testA._.tienes_auto.status === 'EQUAL') && testA._.tienes_auto.current === 97
        ) { // Modificado q diga si  o la primera vez q no hay registros 
          if (testA._.tienes_auto.original === 98){
            toRemove.push(98)
          }
          tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === testA._.tienes_auto.current)
          if (tmpArrTest.length <= 0) { // Solo si existe el registro ..! para aplicar en remove
            toAdd.push(testA._.tienes_auto.current)
          }
          // Revisar Tarjeta de circulacion
          if (testA._.tienes_tarjeta.status === 'MODIFIED') {
            if (testA._.tienes_tarjeta.original!=null){
              tmpArrTest = this.profileRecords.filter(item => item.catalogo_id === testA._.tienes_tarjeta.original) // Tiene que existir
              if (tmpArrTest.length>=0){ 
                toRemove.push(testA._.tienes_tarjeta.original)
              }
            }
            toAdd.push(testA._.tienes_tarjeta.current)
          }
        }
        // ------------------------------
        // Procesamos los datos...! 
        test = 44
        var reqRes = { action: [],  rmovex: [], patch: [], addx: [] }
        var reqErr = { action: 0, rmovex: 0, patch: 0, addx: 0 }
        var mcModel = this.getModelRequest('profileFiltro')

        // ----------------------
        // DAtos a eliminar...! 
        if (toRemove.length > 0) {
          var toDelReqs = []
          for (const delRC of toRemove) {
            toDelReqs.push(this.createAxiosConfig ('remove', mcModel, { delimiters: [delRC] }, true, null))
          }
          for (const delRQx of toDelReqs) {
            if (reqErr.rmovex <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataRemove', reqRes.rmovex, delRQx).then((resx) => {
                var tmpresponseData = reqRes.rmovex[reqRes.rmovex.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.rmovex += 1
                }
              })
            }
          }
          if (reqErr.rmovex > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.rmovex[0], reqErr.rmovex)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // ----------------------
        // lo que se agrega 
        if (toAdd.length > 0) {
          var toAddReqs = []
          for (const addRC of toAdd) {
            var addParams = null
            var testObject = (addRC !== null && typeof addRC === 'object' && Array.isArray(addRC) === false) ? true : false 
            if (testObject === true) { // Objetos como (Otros) que llevan 2 valores
              addParams = addRC
            } else {
              addParams = {catalogo_id: addRC}
            }
            toAddReqs.push(this.createAxiosConfig ('post', mcModel, { delimiters: [], data: addParams }, true, null))
          }
          for (const addRQx of toAddReqs) {
            if (reqErr.addx <= 0) {
              await this.doRequestByPromise(this.$apiV2, 'pibDataAdd', reqRes.addx, addRQx).then((resx) => {
                var tmpresponseData = reqRes.addx[reqRes.addx.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.addx += 1
                }
              })
            }
          }
          if (reqErr.addx > 0) { // Si hay error de server, session o algo redireccionamos..!
            this.ex_handleErrorRequest (reqRes.addx[0], reqErr.addx)
            this.showLoadx(false, 1) 
            return false
          }
        }
        // Terminamos
        this.frmDataOriginal = JSON.parse(JSON.stringify(this.frmData))
        allowNextPage = true

      } else { // seguimos a la siguiente pantalla 
        test = 33
        allowNextPage = true
      }
      // Actualizar Datos de Estatus // WIP 
      var retUPS = null
      if (this.currProfile_status < this.zstatUser.TIENES_AUTO){ // <  20
        await this.hpb_updateProfileStatus( this.zstatUser.TIENES_AUTO ).then(respx=>{
          retUPS = respx
        })
      }
      // -----------
      this.showLoadx(false, 1)
      if (allowNextPage) {
        var newRuta = '/dashboard/personal/uniformes-a'
        this.$router.push(newRuta)
      }
      return true
    },
    async initFormData () {
      var test=11
      var mcModel = this.getModelRequest('profileFiltro')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }

      // Hasta aqui estamos bien y obtenemos los datos de profile
      var myFormConfig = this.createAxiosConfig ('get', mcModel, { categoria_id: '13,54', 'per-page': 'all', expand:'catalogo'}, true, null)

      await this.doRequestByPromise(this.$apiV2, 'pibData', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpih_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpih_Data))
        return false 
      }
      var profileData = reqRes.action[0].data.data
      this.profileRecords = JSON.parse(JSON.stringify(profileData))

      if (Array.isArray(profileData) && profileData.length>=1){ 
        // Manipulamos la info para llenar los datos en los valores 
        // alert('llenando datos')
        var val_autox = null
        var val_circula = null

        profileData.forEach(itemP => {
          if (itemP.categoriaId === 13) { // tiene auto
            val_autox = itemP.catalogo_id
          } else  if (itemP.categoriaId === 54) { // tiene tarjeta circula
            val_circula = itemP.catalogo_id
          }
        })
        var newData = {
          tienes_auto: val_autox,
          tienes_tarjeta: val_circula
        }
        this.frmDataOriginal = JSON.parse(JSON.stringify(newData))
        this.frmData = JSON.parse(JSON.stringify(newData))

      } else { // No hay info todavia 
        this.frmDataOriginal = JSON.parse(JSON.stringify(tplpih_Data))
        this.frmData = JSON.parse(JSON.stringify(tplpih_Data))
      }
      return true 
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_tieneauto -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_tieneauto -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_tieneauto --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_tieneauto -- mounted')
    this.zloadingData = false
    this.showLoadx(true, 2)
    
    if (this.$refs.fldx_tieneAuto){
      this.$refs.fldx_tieneAuto.reset()
    }
    if (this.$refs.fldx_tieneTarjeta){
      this.$refs.fldx_tieneTarjeta.reset()
    }

    if (this.getHelperCat(13).dataLoaded===false){ 
      var testCatA = false 
      await this.helpermix_getCatalog (13, null).then( respx => { 
        this.$cannaDebug('-- psiPage_horario -- categoria 13 tiene auto finish ')
        testCatA = respx 
      })
    }
    if (this.getHelperCat(54).dataLoaded===false){ 
      var testCatB = false 
      await this.helpermix_getCatalog (54, null).then( respx => { 
        this.$cannaDebug('-- psiPage_horario -- categoria 54 tiene tarjeta circulacion finish ')
        testCatB = respx 
      })
    }
    await this._waitRequestProc(200)

    var tieneAutoVals = []
    var tieneTarJCircVals = []

    this.getHelperCat(13).dataCatalog.forEach( item => { 
      tieneAutoVals.push(item.id)
    })
    this.getHelperCat(54).dataCatalog.forEach( item => { 
      tieneTarJCircVals.push(item.id)
    })
    this.z_allowTieneAuto = tieneAutoVals
    this.z_allowTieneTarjeta = tieneTarJCircVals

    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()

    if (this.$refs.fldx_tieneAuto){
      this.$refs.fldx_tieneAuto.reset()
    }
    if (this.$refs.fldx_tieneTarjeta){
      this.$refs.fldx_tieneTarjeta.reset()
    }

    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_tieneauto -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_tieneauto -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_tieneauto -- activated')
    if (this.zinitScreen === true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      await this._waitRequestProc(50)

      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })

      await this._waitRequestProc(200)

      this.$forceUpdate()

      this.zloadingData = false
      this.showLoadx(false, 1)
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_tieneauto -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_tieneauto -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_tieneauto -- destroyed')
  }
}
</script>
